import {ref, reactive, toRefs, defineComponent,onBeforeMount,onMounted,getCurrentInstance} from 'vue';
import ClientRepairChooseUtil ,{IClientRepairChooseDataObj} from './clientRepairChooseUtil';
export default defineComponent({
    name:'personCenter',
    beforeRouteEnter(to,from,next){
        // if(sessionStorage.getItem('userInfo')){
        //     next();
        // }else{
        //     next('/clientLogin');
        // }
        next();
    },
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IClientRepairChooseDataObj=reactive<IClientRepairChooseDataObj>({
            utilInst:{} as any,
            refMap:new Map(),
            otherParams:{
                types:[
                    {name:'维修手册',type:0},
                    {name:'维修视频',type:1}
                ]
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new ClientRepairChooseUtil(proxy,dataObj);
        })
        onMounted(()=>{

        })
        const toPage=async(type:number)=>{
            switch (type) {
                case 0://维修手册
                    await proxy.$router.replace({path:'/clientRepairManual'});
                    break;
                case 1://维修视频
                    await proxy.$router.replace({path:'/clientRepairVideos'});
                    break;
            }
        }
        return{
            ...toRefs(dataObj),toPage
         
        }
    }
});